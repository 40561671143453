
import { Options, Vue } from "vue-class-component";

// Components
import {
  ElContainer,
  ElRow,
  ElCol,
  ElButton,
} from "element-plus";

// Services
import {getYear} from "@/services/app/date";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElContainer,
    ElRow,
    ElCol,
    ElButton,
  },
})
export default class TermsOfUseIndex extends Vue {
  get getYear(): string {
    return getYear();
  }

  get translation(): any {
    return getTranslation([
      "allRightsReserved",
      "privacyPolicy",
      "return",
      "termsOfUse",
      "termsOfUseContent",
    ]);
  }
}
